<template>
  <horizontal-bar-chart :analytics="formattedStats"></horizontal-bar-chart>
</template>

<script>
import HorizontalBarChart from "./HorizontalBarChart";
export default {
  components: { HorizontalBarChart },
  props: {
    value: {
      required: true,
      type: Array
    }
  },
  computed: {
    formattedStats() {
      let stats = {
        x: [],
        y: []
      };

      this.value.forEach(stat => {
        stats.x.push(stat.followers);
        stats.y.push(stat.country.name);
      });

      stats.x = stats.x.reverse();
      stats.y = stats.y.reverse();

      stats.series = [
        {
          type: "bar",
          data: stats.x,
          itemStyle: {
            color: this.$theme.primary
          },
          tooltip: {
            formatter: "{b}: {c} Followers"
          }
        }
      ];

      return stats;
    }
  }
};
</script>
