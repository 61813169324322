<template>
  <v-chart :options="options" :auto-resize="true" ref="chart"></v-chart>
</template>

<script>
export default {
  name: "HorizontalBarChart",
  props: {
    analytics: {
      default: () => []
    },
    valueLabelName: {
      default: "Followers"
    },
    // https://ecomfe.github.io/echarts-doc/public/en/option.html#tooltip.formatter
    tooltipStringTemplate: {
      default: "b"
    }
  },
  computed: {
    values() {
      return this.analytics.map(value => {
        return {
          value: value.followers,
          name: value.age_gender.gender === "female" ? "Female" : "Male"
        };
      });
    },
    options() {
      return {
        backgroundColor: "#fff",
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: this.tooltipFormatter
        },
        xAxis: {
          type: "value",
          boundaryGap: ["0%", "0%"]
        },
        yAxis: {
          type: "category",
          data: this.analytics.y
        },
        series: this.analytics.series
        // legend: this.analytics.legend
      };
    },
    tooltipFormatter() {
      let formatter = "";

      for (let i = 0; i < this.analytics.series.length; i++) {
        formatter +=
          "{" +
          this.tooltipStringTemplate +
          i +
          "}: {c" +
          i +
          "} " +
          this.valueLabelName +
          "<br />";
      }

      return formatter;
    }
  },
  mounted() {
    // Find a way to only show the actually shown series in the Hover tooltip
    // setInterval(() => {
    //     this.$refs.chart.dispatchAction({
    //         type: 'downplay',
    //         seriesIndex: 0
    //     })
    // }, 1000);
  }
};
</script>
