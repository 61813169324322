<template>
  <pie-chart
    :analytics="splitGenderAnalytics"
    :legend="['Male', 'Female']"
  ></pie-chart>
</template>

<script>
import PieChart from "./PieChart";
export default {
  components: { PieChart },
  props: {
    value: {
      required: true,
      type: Array
    }
  },
  computed: {
    splitGenderAnalytics() {
      let data = [
        {
          name: "Male",
          value: 0,
          itemStyle: {
            color: this.$theme.primary
          }
        },
        {
          name: "Female",
          value: 0,
          itemStyle: {
            color: this.$theme.accent
          }
        }
      ];

      this.value.forEach(stat => {
        if (stat.gender === "MALE") {
          data[0].value += stat.followers;
        } else {
          data[1].value += stat.followers;
        }
      });

      return data;
    }
  }
};
</script>
