<template>
  <div class="w-100">
    <horizontal-bar-chart
      :analytics="formattedStats"
      tooltip-string-template="a"
      :style="`height:${height}px; width: 100%; max-width: 100%`"
    ></horizontal-bar-chart>
  </div>
</template>
<script>
import HorizontalBarChart from "./HorizontalBarChart";

export default {
  components: { HorizontalBarChart },
  props: {
    height: {
      default: 300,
      required: false,
      type: Number
    },
    value: {
      required: true,
      type: Array
    }
  },
  computed: {
    formattedStats() {
      let tempStats = {};
      let stats = {
        y: [],
        xMale: [],
        xFemale: []
      };
      this.value.forEach(stat => {
        if (!tempStats[stat.age]) {
          tempStats[stat.age] = {};
        }
        tempStats[stat.age][stat.gender] = stat.followers;
      });
      for (let prop in tempStats) {
        if (Object.prototype.hasOwnProperty.call(tempStats, prop)) {
          stats.y.push(prop);
          stats.xMale.push(
            tempStats[prop]["MALE"] ? tempStats[prop]["MALE"] : 0
          );
          stats.xFemale.push(
            tempStats[prop]["FEMALE"] ? tempStats[prop]["FEMALE"] : 0
          );
        }
      }
      stats.y = stats.y.reverse();
      stats.xMale = stats.xMale.reverse();
      stats.xFemale = stats.xFemale.reverse();
      stats.series = [
        {
          name: "Male",
          type: "bar",
          data: stats.xMale,
          itemStyle: {
            color: this.$theme.primary
          }
        },
        {
          name: "Female",
          type: "bar",
          data: stats.xFemale,
          itemStyle: {
            color: this.$theme.accent
          }
        }
      ];
      stats.legend = {
        data: ["Male", "Female"]
      };
      return stats;
    }
  }
};
</script>
