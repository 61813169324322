<template>
  <div class="primary--text">
    <div v-if="labelType === 'text'" class="mb-2">
      <div class="text-center" v-if="!hasRecommendedValue">
        Invite {{ Number(max - value) }} influencers to reach your goal 😁
      </div>
    </div>
    <div v-else class="mb-2">
      <v-btn text :color="progressBarColor" class="border-round-xl custom-button">
        <span>{{ value }}</span>
        <span>/</span>
        <span class="mr-2">{{ max }}</span>
        <span>participants</span>
      </v-btn>
    </div>
    <div class="invited-progress-bar">
      <v-progress-linear
        v-model="percentValue"
        :color="progressBarColor"
        :active="!hasRecommendedValue"
        rounded
        :height="height"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "InvitedProgressBar",
  props: {
    max: {
      required: true,
      type: Number
    },
    value: {
      required: true,
      type: Number
    },
    tooltip: {
      require: false,
      type: String
    },
    labelType: {
      require: false,
      type: String,
      default: "text"
    },
    height: {
      require: false,
      type: Number,
      default: 7
    }
  },
  computed: {
    progressBarColor() {
      if (this.value <= 18) {
        return "error";
      } else if (this.value >= 19 && this.value <= 23) {
        return "warning";
      } else if (this.value > 23) {
        return "success";
      } else {
        // if not in range
        return "error";
      }
    },
    hasRecommendedValue() {
      return Number(this.max - this.value) <= 0 ? true : false;
    },
    percentValue() {
      const percent = Number(this.max) / 100;
      return Math.floor(this.value / percent);
    }
  }
};
</script>
<style lang="scss" scoped>
.invited-progress-bar {
  pointer-events: none;
}
.custom-button {
  pointer-events: none;
  &::before {
    opacity: 0.14;
  }
}
</style>
