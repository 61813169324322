<template>
  <v-chart
    :options="options"
    :auto-resize="true"
    :style="'min-height:' + minHeight + '; width: 100%'"
  ></v-chart>
</template>

<script>
export default {
  name: "FollowerGrowthChart",
  props: {
    minHeight: {
      default: "400px"
    },
    data: {
      required: true,
      type: Object
    }
  },
  data: () => ({}),
  computed: {
    options() {
      return {
        legend: {
          data: ["line"]
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: "1%",
          containLabel: true
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.data.dates
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: this.data.values,
            type: "line",
            itemStyle: {
              color: this.$theme.primary
            }
          }
        ]
      };
    }
  }
};
</script>
