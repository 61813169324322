<template>
  <v-select
    v-model="selected"
    :label="label"
    hide-details
    :items="items"
    return-object
    item-value="id"
    item-text="username"
  >
    <!-- Selected item -->
    <template slot="selection" slot-scope="prop">
      <v-list-item-avatar>
        <img :src="prop.item.profile_image_url" class="bordered" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="font-weight-bold"
          v-text="prop.item.username"
        ></v-list-item-title>
        <v-list-item-subtitle
          class="text--secondary"
          v-text="prop.item.channel.display_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-avatar>
        <img :src="prop.item.profile_image_url" class="bordered" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="prop.item.username"></v-list-item-title>
        <v-list-item-subtitle
          v-text="prop.item.channel.display_name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      default: "Media"
    },
    items: {
      required: true,
      type: Array
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
