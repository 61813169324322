<template>
  <div>
    <v-avatar
      v-if="channel && channel.profile_image_url"
      :size="avatarSize"
      :class="{ bordered: outlined }"
    >
      <img :src="channel.profile_image_url" />
    </v-avatar>
    <v-avatar
      v-else
      :size="avatarSize"
      :color="color"
      :class="{ bordered: outlined }"
    >
      <span class="font-weight-bold" :class="[displayClass, textColorClass]">
        {{ user.first_name | first_letter }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
import first_letter from "@/helpers/filters/first_letter";

export default {
  filters: {
    first_letter
  },
  props: {
    channel: {
      required: true
    },
    user: {
      required: true,
      type: Object
    },
    size: {
      required: false,
      type: [Number, String]
    },
    displayClass: {
      required: false,
      default: "display-3",
      type: String
    },
    textColorClass: {
      required: false,
      default: "white--text",
      type: String
    },
    color: {
      required: false,
      default: "primary",
      type: String
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    avatarSize() {
      if (this.size) {
        return parseInt(this.size);
      }

      if (this.$vuetify.breakpoint.sm) {
        return 150;
      } else if (this.$vuetify.breakpoint.xs) {
        return 100;
      }

      return 200;
    }
  }
};
</script>
