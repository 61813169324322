<template>
  <div class="favorite-influencer-wrapper">
    <v-icon
      class="error--text"
      v-if="influencer.is_favorite"
      @click="favoriteInfluencer(false)"
      >fas fa-heart</v-icon
    >
    <v-icon class="error--text" v-else @click="favoriteInfluencer(true)"
      >fal fa-heart</v-icon
    >
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "FavoriteInfluencer",
  props: {
    influencer: Object
  },
  methods: {
    ...mapActions("business", [
      "setFaviriteInfluencer",
      "unsetFaviriteInfluencer"
    ]),
    updateState() {
      this.$forceUpdate();
    },
    favoriteInfluencer(value) {
      const params = {
        influencer_uuid: this.influencer.uuid
      };
      if (value) {
        this.influencer.is_favorite = true;
        this.$emit("change", true);
        this.$forceUpdate();
        this.setFaviriteInfluencer(params).then(
          () => {
            this.influencer.is_favorite = true;
            this.$emit("change", true);
          },
          () => {
            this.influencer.is_favorite = false;
            this.$emit("change", false);
            this.$forceUpdate();
            this.setSnackError("Something went wrong.");
          }
        );
      } else {
        this.influencer.is_favorite = false;
        this.$emit("change", false);
        this.$forceUpdate();
        this.unsetFaviriteInfluencer(params).then(
          () => {
            this.influencer.is_favorite = false;
            this.$emit("change", false);
          },
          () => {
            this.influencer.is_favorite = true;
            this.$emit("change", true);
            this.$forceUpdate();
            this.setSnackError("Something went wrong.");
          }
        );
      }
    }
  }
};
</script>
